import { useState, useEffect } from 'react'
import moment from 'moment'

import { DATE_RANGES, COMPARISON_TYPES } from 'constants/calendar'
import {
  createCalendarDateRanges,
  getSelectedDates,
  getComparisonDates,
  getSelectedRange,
  getCustomRange
} from 'helpers/calendarV2'
import { urlParams } from 'helpers/url'
import { getJsonFromLocalStorage, setJsonInLocalStorage } from 'helpers/storage'

const LOCAL_STORAGE_KEY = 'JS_CALENDAR_DATA'
const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Hook to read/write Local storage Calendar Dates
 * @param {String} localStorageKey - Key to store in LocalStorage
 * @returns {Object} - persistedState, setPersistedState
 */
const usePersistedCalendarDates = ({ localStorageKey }) => {
  const persistedState = localStorageKey
    ? getJsonFromLocalStorage(LOCAL_STORAGE_KEY, {})?.[localStorageKey]
    : undefined

  return {
    persistedState,
    setPersistedState: newState => {
      if (localStorageKey) {
        setJsonInLocalStorage(LOCAL_STORAGE_KEY, {
          ...getJsonFromLocalStorage(LOCAL_STORAGE_KEY, {}),
          [localStorageKey]: newState
        })
      }
    }
  }
}

/**
 * Hook to read dates from URL search params
 */
const useSearchParamsDates = ({ setState, useParamsDates }) => {
  const { searchParams, setSearchParams } = urlParams()
  const rangeType = searchParams?.get('period')
  const customStartDate = searchParams?.get('startDate')
  const customEndDate = searchParams?.get('endDate')

  const hasDateParams = Boolean(
    useParamsDates &&
      (rangeType?.length || (customStartDate?.length && customEndDate?.length))
  )

  // Parse dates from URL params and then remove them from the URL
  useEffect(() => {
    if (hasDateParams) {
      setState({ rangeType, customStartDate, customEndDate })

      searchParams.delete('period')
      searchParams.delete('startDate')
      searchParams.delete('endDate')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDateParams])

  return { hasDateParams }
}

/**
 * Hook to use with CalendarDropdown component
 * @param {*} localStorageKey - Key to store in LocalStorage (Optional). If provided will store the custom dates or the selected range in LocalStorage
 * @param {*} defaultRangeType - Default range type to use when no custom dates are selected
 * @param {*} defaultComparisonType - Default comparison type to use when no custom dates are selected. If not provided, no comparison dates will be shown. Still will return comparisonStartDate and comparisonEndDate as "previousPeriod" of selected dates
 * @param {*} defaultState - Default state to use when no persisted state is found in LocalStorage
 * @param {*} minDate - Minimum date to show in the calendar
 * @param {*} maxDate - Maximum date to show in the calendar. Both min and max might filter out ranges options in the calendar
 * @param {*} useParamsDates - If true, will read dates from URL search params and then remove them from the URL (period, startDate, endDate)
 * @returns
 */
const useCalendarDates = ({
  localStorageKey,
  defaultRangeType,
  defaultComparisonType,
  defaultState = {},
  minDate,
  maxDate,
  useParamsDates
}) => {
  // Initial state is from LocalStorage
  const { persistedState, setPersistedState } = usePersistedCalendarDates({
    localStorageKey
  })
  const [state, setState] = useState(persistedState || defaultState)

  const rangeType = state?.rangeType || defaultRangeType
  const comparisonType = state?.comparisonType || defaultComparisonType
  const customStartDate = state?.customStartDate
  const customEndDate = state?.customEndDate
  const selectedPeriodsAmount = state?.selectedPeriodsAmount

  const ranges = createCalendarDateRanges({ minDate, maxDate })
  const selectedRange =
    getCustomRange({ customStartDate, customEndDate, rangeType }) ||
    getSelectedRange({
      defaultRangeType,
      rangeType,
      ranges
    })

  const { startDate, endDate } = getSelectedDates({
    customStartDate,
    customEndDate,
    selectedRange
  })

  const { comparisonStartDate, comparisonEndDate } = getComparisonDates({
    startDate,
    endDate,
    rangeType,
    comparisonType
  })

  // Updates state and LocalStorage
  const setDates = newState => {
    setState(newState)
    setPersistedState(newState)
  }

  // Read dates from URL search params
  const { hasDateParams } = useSearchParamsDates({ setState, useParamsDates })

  return {
    isReady: !hasDateParams, // Wait for async processes to finish before rendering the UI
    calendarData: {
      ranges,
      selectedRange,
      comparisonType,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
      selectedPeriodsAmount,
      minDate,
      maxDate,
      formattedDates: {
        startDate: startDate
          ? moment(startDate).format(DATE_FORMAT)
          : undefined,
        endDate: endDate ? moment(endDate).format(DATE_FORMAT) : undefined,
        comparisonStartDate: comparisonStartDate
          ? moment(comparisonStartDate).format(DATE_FORMAT)
          : undefined,
        comparisonEndDate: comparisonEndDate
          ? moment(comparisonEndDate).format(DATE_FORMAT)
          : undefined
      }
    },
    setDates
  }
}

/* Use for Home Dashboard */
export const useDashboardCalendarDates = () =>
  useCalendarDates({
    localStorageKey: 'dashboard',
    defaultRangeType: DATE_RANGES.lastWeek,
    defaultComparisonType: COMPARISON_TYPES.previousPeriod,
    minDate: moment()
      .subtract(2, 'years')
      .startOf('day')
      .toDate()
  })

/* Use for Ad Analytics */
export const useAdAnalyticsCalendarDates = () =>
  useCalendarDates({
    localStorageKey: 'adAnalytics',
    defaultRangeType: DATE_RANGES.last14Days,
    minDate: moment()
      .subtract(2, 'years')
      .startOf('day')
      .toDate(),
    maxDate: moment()
      .subtract(1, 'day')
      .endOf('day')
      .toDate()
  })

/* Use for Product Database */
export const useProductDatabaseCalendarDates = () =>
  useCalendarDates({
    defaultRangeType: DATE_RANGES.last30Days
  })

/* Use for AI Assist */
export const useAIAssistCalendarDates = ({ startDate, endDate, rangeType }) =>
  useCalendarDates({
    minDate: moment()
      .subtract(2, 'years')
      .startOf('day')
      .toDate(),
    defaultState: {
      rangeType: startDate && endDate ? rangeType || 'custom' : undefined,
      customStartDate: startDate ? moment(startDate).toDate() : undefined,
      customEndDate: endDate ? moment(endDate).toDate() : undefined
    }
  })

/* Use for Profit Overview */
export const useProfitOverviewCalendarDates = () => {
  return useCalendarDates({
    localStorageKey: 'profitOverview',
    defaultRangeType: DATE_RANGES.last30Days,
    defaultComparisonType: COMPARISON_TYPES.previousPeriod,
    minDate: moment()
      .subtract(2, 'years')
      .startOf('day')
      .toDate(),
    useParamsDates: true
  })
}

/* Use for P&L Statement (Profit and Loss) */
export const useProfitAndLossCalendarDates = () =>
  useCalendarDates({
    localStorageKey: 'profitAndLoss',
    defaultRangeType: DATE_RANGES.last30Days,
    defaultComparisonType: COMPARISON_TYPES.previousPeriods,
    minDate: moment()
      .subtract(2, 'years')
      .startOf('day')
      .toDate()
  })
